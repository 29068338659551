import { createGlobalStyle } from 'styled-components';

const TransactionViewModeStyles = createGlobalStyle`
  input, [class^="Button-module_btn"], [class^="FieldFrame-module_fieldContainer"] {
    ${({ disabled }) =>
      disabled &&
      `
        cursor: text;
      `}
  }
[class^="Button-module_btn"] {
    ${({ disabled }) =>
      disabled &&
      `
        pointer-events: none;
        cursor: text;
      `}
  }

  [class^="Button-module_btn"], [class^="ContainerItemsTableRow-module_deleteIcon"], &.delete-vehicle {
    ${({ disabled }) =>
      disabled &&
      `
        display: none;
      `}
  }

  [class^="FieldFrame-module_fieldContainer"] {
    ${({ disabled }) =>
      disabled &&
      `
        // background-color: #FAFBFC !important;
      `}
  }

  #transactionClone, #exportXml, [class^="Pagination-module_buttonsSection"] > button,
  [class^="ToastrManager-module_actions"] > button {
    display: block !important;
    pointer-events: all;
    cursor: pointer;
  }

  [class^="AdminActions-module_btnContainer"] > button {
    display: inline !important;
    pointer-events: all;
    cursor: pointer;
  }
`;

export default TransactionViewModeStyles;
